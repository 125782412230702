import Link from 'next/link'
import Cover from 'components/cover'
import LongText from 'components/long-text'
import SellingPoint from 'components/selling-point'
import SoftLink from 'components/soft-link'
import routes from 'routes'
import cn from 'classnames'
import numeral from 'numeral'

const BookViewComplex = ({
  book,
  showTags = false,
  showIntro = false,
  className,
}: {
  book: any
  showTags?: boolean
  showIntro?: boolean
  className?: any
}): JSX.Element => {
  const link = routes.story.details({
    id: book.bookSkuId || book.bookId,
    title: book.title,
  })
  return (
    <SoftLink href={link}>
      <article className={`flex space-x-3 ${className}`}>
        <div className="w-[96px] flex-none">
          <Cover book={book} />
        </div>
        <div className="flex flex-1 flex-col items-start">
          {book.sellingPoints && <SellingPoint text={book.sellingPoints} />}
          <h3 className="text-sm font-bold mt-0.5 mb-1.5 line-clamp-2 overflow-ellipsis overflow-hidden">
            <Link href={link}>{book.title}</Link>
          </h3>
          <div className="text-xs mb-1">{book.authorName}</div>
          {showIntro ? (
            <div className={cn('text-xs text-gray-400 mb-1.5', 'line-clamp-3')}>
              <LongText text={book.description} />
            </div>
          ) : null}
          <div className="flex-grow"></div>
          <div className="w-full flex justify-between items-center mb-1">
            {showTags && (
              <div className="max-w-[75%] line-clamp-1">
                {book.tags.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      className="inline-block mr-2 px-2 py-1 rounded-full text-2xs text-gray-200 bg-gray-100"
                    >
                      {tag.tagName || tag.name}
                    </div>
                  )
                })}
              </div>
            )}
            {book.viewsCount && book.viewsCount > 9 ? (
              <div className="flex text-xs text-gray-400">
                <span>
                  {numeral(book.viewsCount ?? 0).format('0,0.[0]a')} Views
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </article>
    </SoftLink>
  )
}

export default BookViewComplex

// import Cover from 'components/cover'
// import SellingPoint from 'components/selling-point'
// import SoftLink from 'components/soft-link'
// import routes from 'routes'

// const BookViewComplex = ({
//   book,
//   showTags = false,
//   showIntro = false,
//   className,
// }: {
//   book: any
//   showTags?: boolean
//   showIntro?: boolean
//   className?: any
// }): JSX.Element => {
//   const link = routes.story.details({ id: book.bookId, title: book.title })
//   return (
//     <SoftLink href={link}>
//       <article className={`flex space-x-3 ${className}`}>
//         <div className="w-[96px] flex-none">
//           <Cover book={book} />
//         </div>
//         <div className="flex flex-col items-start">
//           {book.sellingPoints && <SellingPoint text={book.sellingPoints} />}
//           <div>{book.title}</div>
//           <h3 className="text-sm font-bold mt-0.5 mb-1.5 line-clamp-2 overflow-ellipsis overflow-hidden"></h3>
//         </div>
//         {showTags && showIntro}
//       </article>
//     </SoftLink>
//   )
// }

// export default BookViewComplex
