import AppLink from 'components/app-link'

const AppDownload = (): JSX.Element => {
  return (
    <AppLink>
      <div className="p-6 bg-[#FEF8D9]">
        <h1 className="mb-2 text-2xl font-bold">
          Take you Fall into <br />
          Fantasy.
        </h1>
        <p className="mb-5 text-[#65646F]">
          Download AnyStories App to discover more Fantasy Stories.
        </p>
        <img src="/images/platform-download.png" alt="" />
      </div>
    </AppLink>
  )
}

export default AppDownload
