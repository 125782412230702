import Link from 'next/link'
import Cover from 'components/cover'
import SoftLink from 'components/soft-link'
import routes from 'routes'

const BookViewEasy = ({ book }: { book: any }): JSX.Element => {
  const link = routes.story.details({
    id: book.bookSkuId || book.bookId,
    title: book.title,
  })
  return (
    <SoftLink href={link}>
      <article className="flex">
        <div className="w-[120px] flex-none">
          <Cover book={book} />
          <h3 className="min-h-[42px] text-[14px] font-medium mt-[10px] mb-1.5 line-clamp-2 overflow-ellipsis overflow-hidden">
            <Link href={link}>{book.title}</Link>
          </h3>
          <span className="py-1 px-2 inline-flex text-xs text-gray-500 bg-gray-100 rounded  overflow-ellipsis overflow-hidden">
            {book?.tags?.[0]?.['tagName']}
          </span>
        </div>
      </article>
    </SoftLink>
  )
}

export default BookViewEasy
